<template>
    <div class="bgimage h-screen bg">
        <!--Row 1 [Navigation bar]-->
        <div class="flex justify-between bg-slate-100   text-lg   p-4  shadow-lg">
            <div>
                <VaBreadcrumbs class="text-lg" text-slate-100 separator=">" :items="['DBBS', 'Admin', 'Setup']">
                    <VaBreadcrumbsItem href="/dbbs/dbo">DBBS</VaBreadcrumbsItem>
                    <VaBreadcrumbsItem href="/dbbs/dboarea/processing">Processing</VaBreadcrumbsItem>
                    <VaBreadcrumbsItem>Details</VaBreadcrumbsItem>
                </VaBreadcrumbs>

            </div>
            <div class="flex">
                <h1 class="h-45">DBBS Adminstration</h1>
                <button type="button"
                    class="  text-slate-100 hover:bg-yellow-400 hover:text-slate-700 px-4 py-2 text-xl border  pointer rounded-lg"
                    @click="showInfoAction">
                    <VaIcon name="info" class="text-primary-500" size="large" />
                </button>

            </div>
        </div>
        <!--Row 2 [Main Body]-->
        <div class="flex space-x-4 ">

            <div class="w-2/6 relative">
                <div class="sticky top-1">


                    <div class="border-b border-slate-600 ">
                        <p class="absolute text-sm right-4 -top-5" v-if="currentStep"> <span class="text-slate-400">{{
                            file?.id }}</span> {{ currentStep.view }}</p>

                        <VaTabs left v-model="currentTab" class="bg-slate-300">
                            <VaTab class="text-slate-100">Current</VaTab>
                            <VaTab class="text-slate-100">Active Files</VaTab>
                        </VaTabs>
                    </div>
                    <div v-if="currentTab == 0">


                        <div class="flex flex-col space-y-8 bg-slate-300 p-6 ">
                            <div class="relative">
                                <ul class="flex justify-start space-x-4">
                                    <li class="text-slate space-x-4"><span>Company</span> {{ file?.companyName }}</li>
                                    <li class="text-slate space-x-4"><span>Location</span> {{ file?.locationName }}</li>
                                    <li class="text-slate space-x-4"><span>Department</span> {{ file?.departmentName }}
                                    </li>
                                </ul>
                            </div>
                            <div class="flex justify-between items-start">
                                <div>
                                    <h1 class="text-slate-800">
                                        <VaIcon :name="currentStep?.icon" size="1em"></VaIcon>
                                        {{ file?.docLog?.postedDocumentType === 'Purchase' ? 'Production' : (file?.docLog?.postedDocumentType ?? "No Document Type Assigned") }} Details
                                    </h1>
                                    <h3 class="mt-2">{{ currentStep?.desc }}</h3>

                                </div>
                                <div class="flex gap-1">
                                    <VaButton preset="secondary" @click.prevent="cancel"
                                        class="bg-slate-400 px-3 py-1 rounded-lg">Reset</VaButton>
                                    <button @click.prevent="query"
                                        class="bg-slate-400 px-3 py-1 rounded-lg">Query</button>
                                </div>
                            </div>




                        </div>
                        <!--Roow [fom area]-->
                        <div class="sticky top-1 ">

                            <component :is="formView" :file="file" :step="currentStep" @execute="process"
                                class="bg-slate-700   3 p-4 rounded-b-lg border-1 border-slate-200"></component>
                        </div>
                    </div>
                    <div v-if="currentTab == 1" class="bg-slate-200 p-6">

                        <div class="  w-full">
                            <h3 class="mt-4">Open & Processing</h3>
                            <table class="table -mt-2 w-full text-left border-t border-slate-400">
                                <thead>
                                    <tr class="hover:bg-slate-300">
                                        <th class="text-slate-600">File Name</th>
                                        <th class="text-slate-600">Stage</th>
                                        <th class="text-slate-600">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :class="{ 'bg-slate-300 border border-purple-400 rounded py-2': d.id == file?.id }"
                                        @click="setFile(d.id ?? 'none')" class="hover:bg-slate-300 cursor-pointer"
                                        v-for="(d, i) in list" :key="i">
                                        <td class="hover:underline-offset-2 hover:font-bold">
                                            <a><Va-Icon name="preview"></Va-Icon> {{ d.name }}</a>
                                        </td>
                                        <td> {{ d.stage }}</td>
                                        <td>{{ d.status }} </td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                        <div class="  w-full">
                            <h3 class="mt-4">Queries</h3>
                            <table class="table -mt-2 w-full text-left border-t border-slate-400">
                                <thead>
                                    <tr class="hover:bg-slate-300">
                                        <th class="text-slate-600">File Name</th>
                                        <th class="text-slate-600">Stage</th>
                                        <th class="text-slate-600">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :class="{ 'bg-slate-300 border border-purple-400 rounded py-2': d.id == file?.id }"
                                        @click="setFile(d.id ?? 'none')" class="hover:bg-slate-300 cursor-pointer"
                                        v-for="(d, i) in queries" :key="i">
                                        <td class="hover:underline-offset-2 hover:font-bold">
                                            <a><Va-Icon name="preview"></Va-Icon> {{ d.name }}</a>
                                        </td>
                                        <td>{{ d.stage }}</td>
                                        <td>{{ d.status }}</td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                        <div class="  w-full">
                            <h3 class="mt-4">Done</h3>
                            <table class="table -mt-2 w-full text-left border-t border-slate-400">
                                <thead>
                                    <tr class="hover:bg-slate-300">
                                        <th class="text-slate-600">File Name</th>
                                        <th class="text-slate-600">Stage</th>
                                        <th class="text-slate-600">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr :class="{ 'bg-slate-300 border border-purple-400 rounded py-2': d.id == file?.id }"
                                        @click="setFile(d.id ?? 'none')" class="hover:bg-slate-300 cursor-pointer"
                                        v-for="(d, i) in closed" :key="i">
                                        <td class="hover:underline-offset-2 hover:font-bold">
                                            <a><Va-Icon name="preview"></Va-Icon> {{ d.name }}</a>
                                        </td>
                                        <td>{{ d.status }}</td>
                                        <td>{{ d.stage }}</td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>

                    </div>
                </div>
            </div>

            <div class="w-4/6">
                <div class="flex justify-between items-center overflow-x-auto bg-slate-600  ">
                    <div class="text-slate-100 text-md px-3 py-2 bg-slate-700"><span>{{ file?.name }}</span>
                        <span class="ml-8 text-slate-400">Status</span><span class="mx-3 text-slate-300">{{ file?.status
                            }}</span>
                    </div>
                    <div v-if="file?.url" class="space-x-2">
                        <NuxtLink :to="`${getfirstpdf(file?.url)}`" target="_blank"
                            class="bg-slate800 text-slate-100 px-4 py-2 rounded-lg w-32">
                            <VaIcon name="download"></VaIcon> Download
                        </NuxtLink>
                        <!-- <button class="bg-slate-500 text-slate-800 px-4 py-2 rounded-lg w-32">Zoom</button> -->
                        <button @click="showPdfViewer(file?.url ?? '')"
                            class="bg-slate-800 text-slate-200 w-[150px] border border-slate-400 hover:text-slate-50 hover:ring px-4 py-2 rounded-lg ">
                            <VaIcon name="preview"></VaIcon> Full Screen
                        </button>
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                </div>
                <div class="p-6 rounded-lg border-1 border-slate-200 bg-slate-700">
                    <ChartsPdfEmbed :pdfSource="`${file?.url}`"></ChartsPdfEmbed>

                </div>
            </div>
        </div>
        <VaModal v-model="ishowPdfPopup" fullscreen title="PDF">
            <div>

                <ChartsPdfEmbed :pdfSource="showPdf" :scale="1">
                    <VaButton icon="close" @click="ishowPdfPopup = !ishowPdfPopup">Close</VaButton>
                </ChartsPdfEmbed>
            </div>
        </VaModal>
    </div>
</template>


<script setup lang="ts">
const authUser = useAuthUserStore()
const { user } = storeToRefs(authUser)
// apply layout inside
//definePageMeta({layout: 'insidenone'})
const config: Ref<{ title: string, icon: string }> = useState("pageconfig")
config.value.title = "Process Documents"

const route = useRoute()
const { init: initToast, notify } = useToast()
//const pgState =  ref("hasdocs") // hasdocs, nodocs,

const currentTab = ref(0)
// Dialogue help
const showInfoAction = () => {
    console.log("showInfo")
}

const useDocumentsController = () => {
    const userEmail = user.value?.email
    const tKey = tenantKey.value
    const filesStore = useFilesStore()
    const { open: list, queries, closed, currentFile: currentDoc } = storeToRefs(filesStore)

    filesStore.getDboFiles(tenantKey.value, (user.value as IUser)?.email, "open", docFileStatus.open)
    // const {list, nextDoc, getDoc } = useDocumentStore()
    //  const getChangeFile: (fileId: string) => void = changeFile
    //const documents = ref<IDocFile[]>(docs)
    // const donePile = ref<IDocFile[]>([])
    // const queryPile = ref<IDocFile[]>([])
    //const currentDoc = useState<Partial<IDocFile> | undefined>("processDocs:currentDoc", ()=> list?.value[0])
    if (currentDoc.value) {
        filesStore.nextDoc()

    }

    const next = () => {
        currentDoc.value = filesStore.nextDoc()
        //fileId.value = currentDoc.value?.id as string
        // getChangeFile(fileId.value)
    }
    const nextNextFIleToProcess = () => {
        return filesStore.nextFile()
        //fileId.value = currentDoc.value?.id as string
        // getChangeFile(fileId.value)
    }

    // const done = () => {
    //     console.log("finish")
    // }

    return {
        list, queries, closed, next, nextNextFIleToProcess
    }
}

function useSteps() {
    const steps: { [key: string]: IStep } = {
        fileStart: {
            id: 0,
            name: "fileStart",
            desc: "Begin processing document",
            status: "open",
            stage: "open",
            icon: "start",
            note: "record start time, operator",
            view: 'fileStart',
            next: "documentType"
        },
        documentType: {
            id: 3,
            name: "Document Type",
            desc: "Identify the type of document",
            icon: "description",
            status: "detailing",
            stage: "processing",
            note: "Record the type of document",
            view: "documentType",
            next: "validateUc"
        },
        validateUc: {
            id: 1,
            name: "Completeness Checks",
            desc: "The quality and completeness of the document submitted",
            status: "validating",
            stage: "processing",
            icon: "fact_check",
            note: "Check for missing pages, torn, water damage",
            view: 'validateUc',
            next: "verification"
        },
        verification: {
            id: 2,
            name: "Compliance Checks",
            desc: "The document has the required signitures",
            icon: "verified_user",
            status: "verified",
            stage: "processing",
            note: "Check for missing signitures, incorrect signitures",
            view: 'verification',
            next: "docdetails"
        },
        docdetails: {
            id: 4,
            name: "Document Details",
            desc: "Enter the information from the document",
            icon: "edit_document",
            status: "detailing",
            stage: "processing",
            note: "Record the details of the document",
            view: "docdetails",
            next: "done"
        },
        query: {
            id: 5,
            name: "Query",
            desc: "Incomplete or incorrect information",
            status: "open",
            stage: "query",
            icon: "shield_question",
            view: "query",
            next: "fileStart"
        },
        done: {
            id: 6,
            name: "Done",
            desc: "All information has been entered and verified",
            status: "done",
            stage: "processing",
            icon: "beenhere",
            view: "done",
            next: "posted"
        },
        posted: {
            id: 7,
            name: "Posted",
            desc: "The document has been validated, verified and all data entered",
            status: "posted",
            stage: "processing",
            note: 'Thew marked done and removed from processing list',
            icon: "beenhere",
            view: "posted",
            next: "posted"
        },
    }
    const getNextStep = (step: string) => {
        const curentStep = getStep(step)
        return getStep(curentStep.next)
    }
    const getStep = (step: string) => {
        return steps[step]
    }

    return { steps, getStep, getNextStep }
}

const useDocFlowStatus = async (refresh: () => Promise<void>) => {
    // const currentStepIndex = ref("fileStart")
    const currentDoc = ref<IDocFile | undefined>()
    const { steps, getNextStep, getStep } = useSteps()

    const currentStep = ref<IStep>(steps[0])
    const { undo: undoStep, clear } = useRefHistory(currentStep)
    clear()



    const setCurrentFile = async (doc: IDocFile) => {
        if (doc == null) throw new Error("Document is null")
        // 1. save current file
        const hasCurrentDocument = currentDoc.value !== undefined
        if (currentDoc.value !== undefined && currentStep.value !== undefined) {
            currentDoc.value.status = currentStep.value.status
            await fileStore.setActiveView(currentStep.value.view)
        }

        // 2. set current file
        currentDoc.value = doc
        currentStep.value = getStep(currentDoc.value.activeView)
        init(currentDoc.value)
        //Goto last position
    }

    // const preStepIndex = useState("preStepIndex", ()=> 0) 

    const init = (docitem: IDocFile) => {
        docitem.activeView = docitem?.activeView ?? "fileStart"



        const docthis = toRef(docitem)
        notify({
            title: "Document",
            message: `Document ${docthis.value.name} is ready for processing`,
            position: "bottom-right"
        })
        console.log("docthis.value", docthis.value)

    }


    const next = async () => {

        // preStepIndex.value = currentStepIndex.value
        // currentStepIndex.value = currentStep.value.next
        // const step = getStep(currentStepIndex.value)
        if (currentDoc.value !== undefined) {
            console.log("currentDoc.value.activeView", currentDoc.value.activeView)
            currentStep.value = getNextStep(currentStep.value.view)
            currentDoc.value.status = currentStep.value.status
            await fileStore.setActiveView(currentStep.value.view)
        }
        console.log("currentStep.value.view", currentStep.value.view)
    }
    const previous = async () => {
        console.log("restock")
        undoStep()
        currentStep.value = currentStep.value ?? getStep("fileStart")
        const step = getStep(currentStep.value.view)
        currentDoc.value!.status = step.status
        await fileStore.setActiveView(currentStep.value.view)
    }
    const done = async () => {
        console.log("done")
        // preStepIndex.value = currentStepIndex.value
        // currentStepIndex.value = currentStep.value.view
        //  const step = getStep(currentStepIndex.value)
        currentDoc.value!.status = currentStep.value.status
        await fileStore.setActiveView(currentStep.value.view)
    }
    const query = async () => {
        console.log("Query")
        // preStepIndex.value = currentStepIndex.value
        //  currentStepIndex.value = currentStep.value.view
        //  const step = getStep(currentStepIndex.value)
        currentStep.value = getStep("query")
        currentDoc.value!.status = currentStep.value.status
        await fileStore.setActiveView(currentStep.value.view)
    }
    const post = async () => {
        console.log("post")
        // preStepIndex.value = currentStepIndex.value
        // currentStepIndex.value = currentStep.value.view
        // const step = getStep(currentStepIndex.value)
        currentDoc.value!.status = currentStep.value.status
        await fileStore.setActiveView(currentStep.value.view)
    }
    const cancel = async () => {
        //  const tempStep = currentStepIndex.value
        //undoStep()
        // currentStepIndex.value  = preStepIndex.value
        currentStep.value = getStep('fileStart')
        await fileStore.setActiveView(currentStep.value.view)
    }
    const process = async (act: string, value: any, mStep: IStep) => {

        switch (act) {
            case "start":
                await next()

                await fileStore.setStage(file.value?.companyId as string, file.value?.id as string, "processing")
                break
            case "next":
                console.log("action", act, "current step", currentStep.value.name)
                if (mStep.id == 1) { //validating

                    console.log("validation check", value)
                    await fileStore.validationFieldUpdate(file.value?.companyId as string, file.value?.id as string, value as IValidateCheck)
                    await refresh()
                    await next()
                }
                if (mStep.id == 2) { // verified
                    if (file.value == null || file.value.docLog == null) throw new Error("File is null")
                    //file.value.docLog.postedDocumentType = value                    
                    await fileStore.signatureCheckUpdate(file.value?.companyId as string, file.value?.id as string, value)
                    await refresh()
                    //currentStep.value = getStep("documentType")
                    await next()
                }
                if (mStep.id == 3) { // indentify document type
                    await fileStore.updateDocFields(
                        file.value?.companyId as string,
                        file.value?.id as string,
                        value)
                    //  await refresh()
                    await next()
                }
                if (mStep.id == 4) { // detailing
                    await fileStore.updateDocLog(
                        file.value?.companyId as string,
                        file.value?.id as string,
                        file.value?.docLog)
                    await refresh()
                    await next()
                }


                break
            case "update:form":
                //update document fields
                if (file.value == null) throw new Error("File is null")
                //file.value.docLog = file.value?.docLog ?? {}
                file.value.docLog = { ...value }
                console.log("update:form", file.value?.docLog)
                break
            case "prev":
                previous()
                break
            case "query":
                query()
                await fileStore.setStage(file.value?.companyId as string, file.value?.id as string, "query")
                break
            case "cancel":
                cancel()
                await fileStore.setStatus(file.value?.companyId as string, file.value?.id as string, "open")
                break
            case "done":
                console.log("done")
                await fileStore.setStatus(file.value?.companyId as string, file.value?.id as string, "done")
                //await setStage(file.value?.companyId as string, file.value?.id as string, "done")
                break
            case "post":
                console.log("post")

                await fileStore.setStage(file.value?.companyId as string, file.value?.id as string, "reviewing")
                //form useDocumentStore function
                // navigateTo("/dbbs/dboarea/processing")
                await processNextFile()
                break
            case "validate":
                console.log("validate", value)
                //validationFieldUpdate(file.value?.companyId as string, file.value?.id as string, value)
                break
            case "nextdoc":
                // reset and goto next() document
                break
        }
        //update file's current view
        //   await fileStore.setActiveView(currentStep.value.view)

    }

    const formControls: Record<string, Object> = {
        fileStart: defineAsyncComponent(() => import('@/components/file/start.vue')),
        validateUc: defineAsyncComponent(() => import('@/components/file/validate.vue')),
        verification: defineAsyncComponent(() => import('@/components/file/checksignage.vue')),
        documentType: defineAsyncComponent(() => import('@/components/file/doctype.vue')),
        docdetails: defineAsyncComponent(() => import('@/components/file/docdetails.vue')),
        query: defineAsyncComponent(() => import('@/components/file/query.vue')),
        done: defineAsyncComponent(() => import('@/components/file/done.vue')),
        posted: defineAsyncComponent(() => import('@/components/file/posted.vue')),
    }

    const formView = computed(() => {
        const screen = currentStep.value?.view ?? "fileStart"
        //const screen = currentDoc.value?.activeView ?? "fileStart"
        console.log("formView", screen)
        return formControls[screen ?? "start"]
    })
    watchEffect(() => {
        // console.log("watchEffect called", "stepIndex", currentStepIndex.value, "stepStatus", currentStep.value.status)
        if (currentDoc.value == null) return
        // currentStep.value = getStep(currentStepIndex.value)
        currentDoc.value!.status = currentStep.value.status
    })
    if (currentDoc.value)
        init(currentDoc.value)

    return {
        currentDoc, currentStep, previous, done, query, post, cancel,
        process, formView, setCurrentFile
    }
}

//Popup PDF viewer
const showPdf = ref("")
const scale = ref(1)
const ishowPdfPopup = ref(false)
const showPdfViewer = (url: string[] | string) => {
    if (url.length) {
        showPdf.value = url[0]
    } else {
        showPdf.value = url as string
    }
    ishowPdfPopup.value = !ishowPdfPopup.value
}

// init configuration
let fileId = useState("fileid", () => route.query.id as string)
let tenantKey = useState("processDoc:tenantKey", () => route.query.tid as string)
// Check values are present
if (fileId.value == undefined || tenantKey.value == undefined) throw new Error("File Id or Tenant Key is missing")
// load file requested
const fileStore = useFileStore()
const { file, } = storeToRefs(fileStore)
fileStore.init(tenantKey.value)
//await fileStore.getFile(tenantKey.value,fileId.value)    
//useFile(fileId, tenantKey.value)
const { list, queries, closed, nextNextFIleToProcess } = useDocumentsController()

const setFile = async (id: string) => {
    fileId.value = id
    await fileStore.getFile(tenantKey.value, id)
    await setCurrentFile(file.value as IDocFile)
}
const processNextFile = async () => {
    const nextFile = await nextNextFIleToProcess()
    if (nextFile) {
        // await setFile(nextFile.id)
        await setCurrentFile(nextFile)
        await fileStore.getFile(tenantKey.value, nextFile.id)
    } else {
        navigateTo("/dbbs/dboarea/processing")

    }
}
const { process, query, cancel, setCurrentFile, currentStep, formView, } = await useDocFlowStatus(fileStore.refresh)

setFile(fileId.value)


</script>